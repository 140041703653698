
import { Component, Vue, Prop } from 'vue-property-decorator'
import VueRecaptcha from 'vue-recaptcha'

    @Component({
        components: { VueRecaptcha }
    })
    export default class HowToBuyDialog extends Vue {
        @Prop({ default: false }) openDialog: boolean | undefined
        email = ''
        note = ''
        isCaptchaValidated = false

        data() {
            return {
                emailRules: [
                    (value: string) => !!value || 'E-mail is required',
                    (value: string) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) || 'E-mail must be valid'
                ]
            }
        }

        submitData() {
            const visitedPage = window.location.href
            const form: any = this.$refs.form
            if (form.validate()) {
                this.$emit('close')
                let data = {
                    email: this.email.trim(),
                    note: this.note.trim(),
                    visitedPage: visitedPage
                }
                this.$axios.post('/api/utilities/howtobuy', data).then(() => {
                    this.clearDialog()
                })
            }
        }

        closeDialog() {
            this.$emit('close')
            this.clearDialog()
        }

        clearDialog() {
            const form: any = this.$refs.form
            const captcha: any = this.$refs.recaptcha
            form.reset()
            captcha.reset()
            this.isCaptchaValidated = false
        }

        verifyCapcha() {
            this.isCaptchaValidated = true
        }

        expiredCapcha() {
            const captcha: any = this.$refs.recaptcha
            captcha.reset()
            this.isCaptchaValidated = false
        }

    mounted() {
        this.setRecaptchaAriaLabel();
    }

    setRecaptchaAriaLabel() {
        const recaptchaComponent = this.$refs.recaptcha as Vue & { $el: () => any };
        
        if (recaptchaComponent) {
            const recaptchaElement = recaptchaComponent.$el.querySelector("textarea");
            recaptchaElement?.setAttribute('aria-label','reCapcha Verification');
        }
    }
}
